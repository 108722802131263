/*

     _________________________________     O---o                        __  __     _
    |         _  _            _  __   |     O-o       __ _   ___  _ __  \ \/ /  __| |  ___ __   __
    |     /\ |_)|_)|   /\ | |(_ |_    |      O       / _` | / _ \| '_ \  \  /  / _` | / _ \\ \ / /
    ;   _/``\|  |  |__/``\|_| _)|__   |     o-O     | (_| ||  __/| | | | /  \ | (_| ||  __/ \ V /
   ((_(-____________.-.___________.)`)┘    o---O     \__, | \___||_| |_|/_/\_\ \__,_| \___|  \_/
    \__ )        ,'     `.        \ _/     O---o     |___/
    :  :        |_________|       :  :      O-o
    |-'|       ,'-.-.--.-.`.      |`-|       O       Hi! This code is optimized.
    |_.|      (( (*  )(*  )))     |._|      o-O      Unoptimized website  @ https://source.catalog.genxdev.net/
    |  |       `.-`-'--`-'.'      |  |     o---O     allows you to step through Typescript and set
    |-'|        | ,-.-.-. |       |._|     O---o     breakpoints.
    |  |        |(|-|-|-|)|       |  |      O-o
    :,':        |_`-'-'-'_|       ;`.;       O       Application : 'catalog'
     \  \     ,'           `.    /._/       o-O      Filename    : 'main.ts'
      \/ `._ /_______________\_,'  /       o---O
        `.| |  |           |  |,'╔═════════════════╡@license genXdev All Rights Reserved╞═╗
          `.|  |           |  |  ║(the "Software") are the exclusive property of genXdev  ║
╔═══════════╡  |           |  ╞══╝Unauthorized copying, distribution, modification, or use╚══════════════════╗
║of this Software, in whole or in part, is strictly prohibited. For permission requests, please contact:     ║
║genXdev, info@genXdev.net Written permission from genXdev is required for any use of this Software,         ║
║including but not limited to, reproduction, adaptation, distribution, performance, display, or the creation ║
║of derivative works based upon the Software. THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,║
║EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR║
║PURPOSE, TITLE, AND NON-INFRINGEMENT. IN NO EVENT SHALL GENXDEV BE LIABLE FOR ANY CLAIM, DAMAGES, OR OTHER  ║
║LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT, OR OTHERWISE, ARISING FROM, OUT OF, OR IN CONNECTION WITH║
║THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.╔═════════════════════════════════════════════════╝
╚══════════════════════════════════════════════════════════╝

*/

import { ThumbnailPanel } from "./catalog/ThumbnailPanel.js";
import { RegisterServiceWorker } from "../../../../shared/typescript/AppHelpers/AppServiceWorkerHelper.js";

ReactDOM.render(
  React.createElement(ThumbnailPanel),
  document.querySelector("#appDisplay1"),
);

RegisterServiceWorker(true, true);

function scrollToChildDiv(childDivId: string) {
  const childDiv = document.getElementById(childDivId);
  if (childDiv) {
    childDiv.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  } else {
    console.error(`Element with ID ${childDivId} not found.`);
  }
}

window.addEventListener('message', (event: any) => {

  if (!!event.data && typeof event.data.su === "string") {
    document.title = event.data.su;
    scrollToChildDiv(event.data.su);
  }
});